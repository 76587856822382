export const DATA_POINTS_1 =
    'name,symbol,lastPrice,chg,chg%,highPrice,lowPrice,volume,tradeDate,tradeTime,timezoneAbbreviation';
export const DATA_POINTS_2 =
    'name,symbol,lastPrice,chg,chg%,highPrice,lowPrice,tradeDate,tradeTime';
export const DATA_POINTS_3 =
    'name,symbol,yesterdayClosePrice,upDown,lastPrice,chg%,week52HighPrice,week52LowPrice,dividendYieldCurrent,lastDividend,lastDividendDate,oneMonthReturn,threeMonthReturn,ytdReturn,oneYearReturn,threeYearReturn,fiveYearReturn,tenYearReturn,tradeDate,tradeTime';
export const DATA_POINTS_4 =
    'name,symbol,yesterdayClosePrice,upDown,lastPrice,chg%,week52HighPrice,week52LowPrice,dividendYieldCurrent,lastDividend,forwardPERatio,oneMonthReturn,threeMonthReturn,ytdReturn,oneYearReturn,threeYearReturn,fiveYearReturn,tenYearReturn,tradeDate,tradeTime';
export const DATA_POINTS_5 =
    'name,symbol,returnEndDate,ytdReturn,oneDayReturn,oneWeekReturn,oneMonthReturn,threeMonthReturn,sixMonthReturn,oneYearReturn,twoYearReturn,threeYearReturn,fiveYearReturn,tenYearReturn,fifteenYearReturn';
